import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GlobalProviderService {

  // private API_URL = environment.API_URL;
  constructor(
    private http: HttpClient,
  ) { }

  /**
 * Performs HTTP POST Request on given API End Point with given POST data
 * @param apiEndPoint API End Point to perform POST request on
 * @param postObj POST Data to be sent in request
 */
  public httpPost(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.post(apiEndPoint, postObj)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
  * Performs HTTP Put Request on given API End Point with given Put data
  * @param apiEndPoint API End Point to perform Put request on
  * @param postObj POST Data to be sent in request
  */
  public httpPut(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.put(apiEndPoint, postObj)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP GET Request on given api endpoint
   * @param apiEndPoint API End-point to perform GET request on
   */
  public httpGet(apiEndPoint: string) {
    return new Promise((resolve, reject) => {
      this.http.get(apiEndPoint)
        .subscribe((res: any) => {
          console.log(res);
          if (res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
 * Performs HTTP POST Request on given API End Point with given POST data
 * @param apiEndPoint API End Point to perform POST request on
 */
public httpDelete(apiEndPoint: string) {
  return new Promise((resolve, reject) => {
    this.http.delete(apiEndPoint)
      .subscribe((res: any) => {
        if (res.data != null) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      }, err => {
        console.error(err);
        reject(err);
      });
  });
}

}
