import { Injectable } from '@angular/core';
import { GlobalProviderService } from './global-provider.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { IAppSettings, IAssignment, IIncentive, IVehicle, ISurge, IDocumentsMetadata, ICountry, IState, ISuperAdmins, ICity, IZone } from '../models/global-settings';
import { IUser, IWalletLog, IPayoutLog } from '../models/user';
import { IDelivery } from '../models/delivery';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'; 


@Injectable({
  providedIn: 'root',
})
export class CmoRidersApiService {
  public localUrl;


  constructor(
    private globalProvider: GlobalProviderService,
    private firestore: AngularFirestore,
    public http: HttpClient
  ) { }

  /*
  * Global Settings
  */
  public getAppSettings(): AngularFirestoreDocument<IAppSettings> {
    let appSettingCollection: AngularFirestoreDocument<IAppSettings>;
    appSettingCollection = this.firestore.collection('/global_settings').doc<IAppSettings>('app_settings');
    return appSettingCollection;
  }

  public getSuperAdmins(): AngularFirestoreDocument<ISuperAdmins> {
    let superAdminsCollection: AngularFirestoreDocument<ISuperAdmins>;
    superAdminsCollection = this.firestore.collection('/superAdmins').doc<ISuperAdmins>('users');
    return superAdminsCollection;
  }

  public getAssignmentSettings(): AngularFirestoreDocument<IAssignment> {
    let assignmentSettingCollection: AngularFirestoreDocument<IAssignment>;
    assignmentSettingCollection = this.firestore.collection('/global_settings').doc<IAssignment>('assignment');
    return assignmentSettingCollection;
  }

  public getIncentiveSettings(): AngularFirestoreDocument<IIncentive> {
    let incentiveSettingCollection: AngularFirestoreDocument<IIncentive>;
    incentiveSettingCollection = this.firestore.collection('/global_settings').doc<IIncentive>('incentives');
    return incentiveSettingCollection;
  }

  public getSurgeSettings(): AngularFirestoreDocument<ISurge> {
    let incentiveSettingCollection: AngularFirestoreDocument<ISurge>;
    incentiveSettingCollection = this.firestore.collection('/global_settings').doc<ISurge>('surge');
    return incentiveSettingCollection;
  }

  public getVehicleSettings(): AngularFirestoreDocument<IVehicle> {
    let incentiveSettingCollection: AngularFirestoreDocument<IVehicle>;
    incentiveSettingCollection = this.firestore.collection('/global_settings').doc<IVehicle>('vehicle');
    return incentiveSettingCollection;
  }


  public getDocumnentMetadata(): AngularFirestoreDocument<IDocumentsMetadata> {
    let doscumentMetadataCollection: AngularFirestoreDocument<IDocumentsMetadata>;
    doscumentMetadataCollection = this.firestore.collection('/requiredDocuments').doc<IDocumentsMetadata>('ixjyZQuSBgCKQtlE9U0d');
    return doscumentMetadataCollection;
  }

  /*
  * Users Related Services
  */

  public getUsers(): AngularFirestoreCollection<IUser> {
    let usersCollection: AngularFirestoreCollection<IUser>;
    usersCollection = this.firestore.collection('/users');
    return usersCollection;
  }

  public getUserDetails(userId: string): AngularFirestoreDocument<IUser> {
    let userCollection: AngularFirestoreDocument<IUser>;
    userCollection = this.firestore.collection('/users').doc<IUser>(userId);
    return userCollection;
  }

  public searchRiders(searchValue): AngularFirestoreCollection<IUser> {
    let results:any;
    results=this.firestore.collection('/users', ref => ref
    .orderBy("email")
    .startAt(searchValue.toLowerCase())
    .endAt(searchValue.toLowerCase()+"\uf8ff").where('appState','==','ON'));
    return results;
  }
  public assignRider(uid,deliveryId): any {
    let results:any;
    results=this.firestore.collection('/users').doc(uid).update({
      appState:'NEWDELIVERY',
      tempDelivery:deliveryId
    });
    return results;
  }


  public getWalletLogs(userId: string): AngularFirestoreCollection<IWalletLog> {
    let userWalletLogCollection: AngularFirestoreCollection<IWalletLog>;
    userWalletLogCollection = this.firestore.collection('/users').doc<IUser>(userId).collection('/walletLog');
    return userWalletLogCollection;
  }

  public getPayoutLogs(userId: string) {
    return this.http.get(environment.cloudFunctionsUrl+'/stripeGetRiderPayouts?weeks=52&rider_id=' + userId);
  }

  public checkRiderDocs(userId: string) {
    return this.http.get(environment.cloudFunctionsUrl+'/notifyRiderExpiredDocs?rider_id=' + userId);
  }


  /*
  * Deliveries Related Services
  */

  public getDeliveries(): AngularFirestoreCollection<IDelivery> {
    let deliveriesCollection: AngularFirestoreCollection<IDelivery>;
    deliveriesCollection = this.firestore.collection('/deliveries');
    // deliveriesCollection = this.firestore.collection<IDelivery>('/deliveries', ref => ref.orderBy('accepted_timestamp','desc'));
   
    return deliveriesCollection;
  }

  public getDeliveryDetails(userId: string): AngularFirestoreDocument<IDelivery> {
    let userCollection: AngularFirestoreDocument<IDelivery>;
    userCollection = this.firestore.collection('/deliveries').doc<IDelivery>(userId);
    return userCollection;
  }

  /*
  * Zone Related Services
  */

  public getCountries(): AngularFirestoreCollection<ICountry> {
    let usersCollection: AngularFirestoreCollection<ICountry>;
    usersCollection = this.firestore.collection('/global_settings').doc<IVehicle>('app_settings').collection('country');
    return usersCollection;
  }

  public getStates(countryId?: string): AngularFirestoreCollection<IState> {
    let statesCollection: AngularFirestoreCollection<IState>;
    statesCollection = this.firestore.collection('/global_settings').doc<IVehicle>('app_settings').collection('state');
   
   
    return statesCollection;
  }

  public getCities(): AngularFirestoreCollection<ICity> {
    let usersCollection: AngularFirestoreCollection<ICity>;
    usersCollection = this.firestore.collection('/global_settings').doc<IVehicle>('app_settings').collection('city');
    return usersCollection;
  }

  public getZones(): AngularFirestoreCollection<IZone> {
    let usersCollection: AngularFirestoreCollection<IZone>;
    usersCollection = this.firestore.collection('/global_settings').doc<IVehicle>('app_settings').collection('zones');
    return usersCollection;
  }

  //call http endpoint
  public reassignOrder(orderId: string): Promise<any> {
    return this.globalProvider.httpGet(environment.cloudFunctionsUrl+'/reAssign?deliveryId=' + orderId);
  }

}
