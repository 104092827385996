import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import { AuthService } from "./auth-service.service";

import { CmoRidersApiService } from '../services/cmo-riders-api.service';
import { AngularFirestoreDocument } from "@angular/fire/firestore";
import { IAppSettings, ISuperAdmins } from "../models/global-settings";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";

@Injectable()
export class AuthGuard implements CanActivate {

  superAdminsCollection: AngularFirestoreDocument<ISuperAdmins>;
  constructor(private auth: AuthService, private router: Router,public cmoRidersApiService: CmoRidersApiService,public rxFormBulder: RxFormBuilder,) {}
  
  canActivate(routeerstate?: any) {
    let url = routeerstate._routerState.url; // this url is unactivated route which the user is trying to enter;
    return this.auth.user
      .take(1)
      .map((authState) => !!authState)
      .do((authenticated) => {
        this.superAdminsCollection = this.cmoRidersApiService.getSuperAdmins();
        let subscription1 = this.superAdminsCollection.valueChanges().subscribe(data => {
          if (authenticated && data['emails'].includes(this.auth.userDetails.email)) {
            return true;
          }
          this.router.navigate(["auth/login"]);
          return false;
        });
      });


      // Verified Email Only ----->>>>>>

      // return this.auth.user
      // .take(1)
      // .map((authState) => !!authState)
      // .do((authenticated) => {
      //   if (authenticated) {
      //     if (
      //       this.auth.userDetails.emailVerified != null &&
      //       this.auth.userDetails.emailVerified == true
      //     ) {
      //       return true;
      //     }
      //     if (
      //       this.auth.userDetails.emailVerified != null &&
      //       this.auth.userDetails.emailVerified == false
      //     ) {
      //       this.router.navigate(["auth/verify-email-address"]);
      //       return false;
      //     }
      //   }
      //   this.router.navigate(["auth/login"]);
      //   return false;
      // });  
  }
}
