import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Observable } from 'rxjs-compat';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AuthService {
  public user: Observable<firebase.User>;
  public userDetails: firebase.User = null;
  afAuth: any;
  ngZone: any;
  usr: any;

  constructor(private fauth: AngularFireAuth, private router: Router, private _firestore: AngularFirestore) {
    this.user = fauth.authState;
  //  this.usr = this.auth.userDetails;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
          localStorage.setItem('user', JSON.stringify(this.userDetails));
        }
        else {
          this.userDetails = null;
        }
      }
    );
  }

  addAdmin(uid, fullName, email, password, mobile, credits){
    return this._firestore.firestore.collection('users').add(
      {
        'uid': uid,
        'email': email,
        'password': password,
        'mobile':mobile,
        'fullName':fullName,
        'company':null,
        'image':null,
        'credits':
        {
          'pendingexperiments' :  0,
          'totalcompletedexperiments' :  0,
          'totalexperiments':  0,
          'totalprojects': 0
      } ,
        'count': {
          'pendingexperiments' :  0,
          'totalcompletedexperiments' :  0,
          'totalexperiments':  0,
          'totalprojects': 0
      }
         
    
      },
    );
  }

  addAdmin1(uid, fullName, email, mobile){
 //   return this._firestore.firestore.collection('users').add(
  return this._firestore.firestore.collection('users').doc(uid).set(
      {
        'uid': uid,
        'email': email,
        'mobile':mobile,
        'fullName':fullName,
        'image':null,
        'credits':
        {
          // 'bpp' :  15,
          // 'buildgenerator' :  15,
          // 'generator':  15,
          // 'mergesub': 15,
          // 'propertygenerator': 10,
          // 'retrosynthesis': 10,
          // 'substructure': 15,
      } ,
      //   'count': {
      //     'pendingexperiments' :  0,
      //     'totalcompletedexperiments' :  0,
      //     'totalexperiments':  0,
      //     'totalprojects': 0
      // }
         

      },
    );
  }
  async UpdateProfile(displayName: string, photoURL?: string) {
    const profile = {
        displayName: displayName,
        photoURL: photoURL
    }
    return (await this.fauth.currentUser).updateProfile(profile)
}

  signUp(email, password) {
    return this.fauth.createUserWithEmailAndPassword(email, password);
  }
   
  SendVerificationMail() {
    return this.fauth.currentUser.then(() => {
      this.router.navigate(['auth/verify-email-address']);
    })
  }
  requestPass(email) {
    return this.fauth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code, newPassword) { // param: oobCode=<code>
    return this.fauth.confirmPasswordReset(code, newPassword);
  }

  /*verifyPasswordResetCode(code){
    return this._firebaseAuth.auth.verifyPasswordResetCode(code);
  }*/

  signInWithEmail(email, password) {
    return this.fauth.signInWithEmailAndPassword(email, password)
   }

  isLoggedIn() {
    if (this.userDetails == null) {
      return false;
    } else {
      return true;
    }
  }
  

  logout() {
    this.fauth.signOut()
      .then((res) => this.router.navigate(['/auth/login'])).then(e=>{location.reload()});
  }


  signInWithEmailAndPassword(email: string, password: string) {
    return this.fauth.signInWithEmailAndPassword(email, password);
}

createUserWithEmailAndPassword(email: string, password: string) {
    return this.fauth.createUserWithEmailAndPassword(email, password);
}

  async updatePassword(password: string) {
    return (await this.fauth.currentUser).updatePassword(password);
}


requestpassword(email: string) {
    return this.fauth.sendPasswordResetEmail(email);
}
}


